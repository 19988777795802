:root {
	--hrBackground: #4396b7;
}

@font-face {
	font-family: "Lokem-Headings";
	src: url("../src/fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
	src: url("../src/fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
	src: url("../src/fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
	font-weight: 600;
}

@font-face {
	font-family: "Lokem-Lighter";
	src: url("../src/fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
	src: url("../src/fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
	src: url("../src/fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
	font-weight: 600;
}

@font-face {
	font-family: "Lokem-Medium";
	src: url("../src/fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
	src: url("../src/fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
	src: url("../src/fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
	font-weight: 600;
}
.text-danger{
    color: red;
} 
.error{
	color: red;
	font-family: Lokem-Medium;
    font-size: 14px;

  }
.main-color{
    background: var(--mainBackground);
}
.train-main1{
    width: 84%;
    padding-left: 8%;
    padding-right: 8%;
    text-align: center !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #224f65;
    color: black;

}

.tyt-heading {
    text-align: left;
    color:#E0E0E0;
    padding-left: 10px;
}
.tyt-heading-line-main {
    margin-bottom: 20px;
    margin-left: 14px;
}
.tyt-heading-line1 {
    width: 60px;
    border: 1.9px solid var(--hrBackground);
    background: var(--hrBackground);
    float: left;
    margin-left: auto;
}
.container {
    border-radius: 5px;
    text-align: left;
    color:#E0E0E0;
}
.textField{
    height: 60px;
    margin-left: 5px;
    margin-right: 5px;
}
.btn-main {
    margin-bottom: 5px;
    color: #1c495b !important;
    width: 130px;
    border: none;
    cursor: pointer;
    padding-bottom: 10px;
    border-radius: 10px;
    font-size: 20px;
   /* background: #CFD8DC !important;*/
    padding-top: 10px;
    margin-bottom: 30px;
    margin-top: 30px;
    text-align: center;
}

.btn-main:hover {
    background-color: #00b7ff ;
  }


.contactMsg {
    text-align: center;
    color: white;
    font-size: 16px;
}
#book-call-section input, select  {
    width: 100%;
    height: 40px;
    padding: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 15px;
    padding-left: 6px;
    border-radius: 3px;
    vertical-align: middle;
    margin-bottom :0px !important;
    background-color: #CFD8DC;
    font-size: 14px;
}
#book-call-section  textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 15px;
    padding-left: 6px;
    border-radius: 3px;
    vertical-align: middle;
    margin-bottom :0px !important;
    background-color: #CFD8DC;
    font-size: 14px;
    font-family: Lokem-Lighter !important;
}
.btn{
    text-align: center;
}
    .label {
        font-family: Lokem-Medium;
    }