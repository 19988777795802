:root {
	--mainBackground: #224f65;
    --imageBackground: #4396b7;
    --hrBackground: #FF8F00;
}
@font-face {
	font-family: "Lokem-Headings";
	src: url("../src/fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
	src: url("../src/fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
	src: url("../src/fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
	font-weight: 600;
}

@font-face {
	font-family: "Lokem-Lighter";
	src: url("../src/fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
	src: url("../src/fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
	src: url("../src/fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
	font-weight: 600;
}

@font-face {
	font-family: "Lokem-Medium";
	src: url("../src/fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
	src: url("../src/fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
	src: url("../src/fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
	font-weight: 600;
}

.top-image{
  width:100%;
}
.main1{
  background: var(--colorBackground) !important;

}
.about-main{
  text-align: center;
  background: #224f65 !important;
  color:#fff !important;
  padding-bottom: 100px;
  
	padding-top: 100px;
}

.train-your-main {
	width: 84%;
	padding-left: 8%;
	padding-right: 8%;
	text-align: center !important;
	background-repeat: no-repeat;
	background-position: center;
  color:black;
}



.main-respons{
    text-align: center;
    color: #224f65;
    padding-top: 40px !important;
    background: #ECEFF1;
  
}
.about-para-text{
    text-align: left;
    font-family: lokem-lighter;
    margin-top: 40px;
    letter-spacing: 0.2px;
    font-size: 20px;
    line-height: 25px;
}
.aboutus-main{
    display: inline-flex;
    padding-top: 0px;
    justify-content: center;
    background: #ECEFF1;
    padding-bottom: 15px;
  
    
}
.about-img{
    width:46%;
    margin-top: 30px;
    margin-bottom: 30px;
    
}

    .about-logo-img {
        width: 70%;
        height: 410px;
        padding-top: 10px;
        object-fit: cover;
    }
    .main-about-respns{
        text-align: right;
        width:40%;

 
      }
    


.tyt-paras {
    width: 100%;
    display: inline-flex;
  }
  .tyt-col1 {
    text-align: center;
  }
.tyt-heading-line {
	  width: 51px;
    border: 1.9px solid #4396b7;
    background: #4396b7;
}
.our-3cs-image-main {
    padding-left: 45px;
    object-fit: cover;
  }
  
  .each-para-div {
	padding-bottom: 20px;
}
.offer-para1 {
	margin-top: 0px;
}.tyt-sub-heading {
	color: #bd8f14;
  padding-bottom: 10px;
}
@media (max-width: 995px) {
  .about-main{
    text-align: center;
    background: #224f65 !important;
    color:#fff !important;
    padding-bottom: 1px;
    
    padding-top: 0px;
  }
  .tyt-col1 {
    text-align: center;
    padding-top: 25px;
  }
  .tyt-heading-line {
    width: 40px;
      border: 1.9px solid #4396b7;
      background: #4396b7;
  }
  .about-img{
    width:40%;
    margin-top: 30px;
    margin-bottom: 30px;
}
.aboutus-main{
  width:84%;
  display: inline-block;
  
}
.main-respons{
  text-align: center;
  color:#224f65;

}
.about-para-text{
  text-align: left;
  letter-spacing: 0.2px;
  line-height: 25px;
  font-family: lokem-lighter;
  margin-top: 20px;
}
.about-img{
  width:100%;
  margin-top: 30px;
  margin-bottom: 30px;
}

  .about-logo-img {
      width: 100%;
      
  }
  .main-about-respns{
      text-align: center;
      width:100%;

  }
  .train-your-main {
    width: 84%;
    padding-left: 8%;
    padding-right: 8%;
    text-align: center !important;
    background-repeat: no-repeat;
    background-position: center;
    color:black;
    
  }
  .main-respons{
    text-align: center;
    color: #224f65;
    padding-top: 40px !important;
    background: #ECEFF1;
  
}


}