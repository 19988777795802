@font-face {
  font-family: "Lokem-Headings";
  src: url("./fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
  src: url("./fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
  src: url("./fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Lokem-Lighter";
  src: url("./fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
  src: url("./fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
  src: url("./fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Lokem-Medium";
  src: url("./fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
  src: url("./fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
  src: url("./fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
  font-weight: 600;
}
.cookiePopup {
  z-index: 10;
  position: fixed;
  bottom: 0px;
  background: #fff;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  padding-bottom: 20px;
}
.cookie-container {
  text-align: center;
  padding-top: 10px;
  font-size: 20px;
}
.cookie-btn-container {
  display: inline-flex;
  padding-top: 15px;
}
.allow-btn-right {
  padding-right: 50px;
}
.cookie-btns {
  width: 150px;
  font-family: Lokem-Headings;
  font-size: 20px;
  border-radius: 25px;
  background: #cba53c;
  cursor: pointer;
  border: 1px solid;
  color: #fff;
  padding-bottom: 8px;
  padding-top: 8px;
}
.manageCookiesMain {
  background: #fff;
  width: 60%;
  border: 2px solid #8496a7;
  margin-right: 20%;
  margin-left: 20%;
  padding-left: 25px;
  padding-right: 25px;
  text-align: center;
  height: 500px;
  overflow-y: auto;
  padding-bottom: 10px;
  margin-top: 10px;
  color: #000;
}
.cookieHeading {
  margin-top: 20px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 45px;
  width: 86%;
  margin-left: 7%;
  margin-right: 7%;
  color: #cba53c;
}
.cookieReason {
  text-align: center;
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: Lokem-Lighter;
}
.cookie-para {
  color: black;
}
.cookie-para a {
  color: #000;
}
.cookieLeftRight {
  display: inline-flex;
  margin-bottom: 25px;
  margin-right: 20px;
}
.cookieLeftSection {
  width: 30%;
  text-align: center;
  font-size: 22px;
  font-family: Lokem-Headings;
}
.cookieRightSection {
  width: 70%;
  text-align: left;
  font-family: Lokem-Lighter;
  font-size: 20px;
}
.switchMain {
  margin-top: 8px;
  margin-bottom: 8px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch input:checked + .slider {
  background-color: #7ed956;
}
.switch input:focus + .slider {
  box-shadow: 0 0 1px #7ed956;
}
.switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.switch .slider.round {
  border-radius: 34px;
}
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.switch .slider.round:before {
  border-radius: 50%;
}
.switch .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.saveBtn {
  width: 50%;
  text-align: left;
}
.savePrefBtn {
  background: #8194a5;
  color: #fff;
  border: none;
  width: 130px;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 25px;
  height: 55px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
}
.acceptBtn {
  width: 50%;
}
.acceptAllBtn {
  background: #cba53c;
  color: #fff;
  border: none;
  width: 130px;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 25px;
  height: 55px;
  cursor: pointer;
}
.acceptSaveBtn {
  width: 60%;
  text-align: center;
  display: inline-flex;
  margin-left: 20%;
  margin-right: 20%;
}
.policyAnchor {
  color: #000;
}
@media (max-width: 995px) {
  .manageCookiesMain {
    background: #fff;
    width: 100%;
    border: none;
    margin-right: 0;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    height: 500px;
    overflow-y: auto;
    padding-bottom: 10px;
    margin-top: 0;
  }
  .cookieHeading {
    margin-top: 20px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 26px;
    width: 86%;
    margin-left: 7%;
    margin-right: 7%;
    color: #cba53c;
  }
  .cookieReason {
    text-align: center;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-family: Lokem-Lighter;
  }
  .cookieLeftSection {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-family: Lokem-Headings;
  }
  .cookieLeftRight {
    display: inline-block;
    margin-bottom: 25px;
    margin-right: 0;
  }
  .cookieRightSection {
    width: 100%;
    text-align: center;
    font-family: Lokem-Lighter;
    font-size: 17px;
  }
  .acceptSaveBtn {
    width: 100%;
    text-align: center;
    display: inline-flex;
    margin-left: 0;
    margin-right: 0;
  }
  .saveBtn {
    width: 50%;
    text-align: center !important;
  }
  .accept-content {
    padding-top: 24px;
  }
  .cookiePopup {
    z-index: 10;
    position: fixed;
    top: calc(50% - 180px);
    bottom: initial;
    background: #fff;
    width: 92%;
    height: auto;
    margin: 0;
    margin-left: 4%;
    margin-right: 4%;
    padding: 0;
    padding-bottom: 0;
  }
  .cookie-btns {
    width: 150px;
    font-family: Lokem-Headings;
    font-size: 20px;
    border-radius: 25px;
    background: #cba53c;
    cursor: pointer;
    border: 1px solid;
    color: #fff;
    padding-bottom: 8px;
    padding-top: 8px;
  }
  .cookie-container {
    text-align: center;
    padding-top: 20px;
    font-size: 20px;
    padding-bottom: 0px;
  }
  .cookie-btn-container {
    display: initial;
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .allow-btn-right {
    padding-right: 0px;
    margin-bottom: 10px;
  }
  .ck-privacy-policy {
    color: #de7b28;
  }
  .ess-ck {
    display: initial;
  }
  .left-ess-ck {
    width: 100%;
  }
  .ess-ck-container {
    text-align: center;
    font-size: 20px;
  }
  .ess-ck-heading {
    text-align: left;
    font-size: 33px;
    padding-left: 18px;
  }
  .ess-ck-content {
    text-align: left;
    padding-left: 20px;
    padding-top: 15px;
    font-family: "Lokem-Lighter";
    width: 85%;
  }
  .ess-ck-terms-msg {
    text-align: left;
    font-size: 23px;
    padding-left: 10px;
    font-family: "Lokem-Headings";
    width: 90%;
    padding-top: 40px;
    margin-left: 0;
    padding-left: 20px;
    padding-bottom: 10px;
  }
  .right-ess-ck {
    float: initial;
    width: 100%;
    text-align: right;
    padding-bottom: 20px;
    padding-left: 20px;
  }
  .right-ess-content {
    text-align: center;
    width: 100%;
    display: inline-flex;
  }
  .ess-terms-chk-div {
    padding-top: 0px;
  }
  .allow-ck-div {
    margin-top: 0px;
    margin-left: 10px;
  }
  .cookie-content {
    margin-left: 7%;
    margin-right: 7%;
    width: 88%;
  }
}
