 :root {
	--mainBackground: #224f65;
    --imageBackground: #4396b7;
    --hrBackground: #FF8F00;
}
@font-face {
	font-family: "Lokem-Headings";
	src: url("../src/fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
	src: url("../src/fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
	src: url("../src/fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
	font-weight: 600;
}

@font-face {
	font-family: "Lokem-Lighter";
	src: url("../src/fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
	src: url("../src/fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
	src: url("../src/fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
	font-weight: 600;
}

@font-face {
	font-family: "Lokem-Medium";
	src: url("../src/fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
	src: url("../src/fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
	src: url("../src/fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
	font-weight: 600;
}

  .mainDiv {
	width: 80%;
	background: #224f65;
	padding-left: 10%;
	padding-right: 10%;
	padding-top: 50px;
    padding-bottom: 50px;
	text-align: center;
}

.back-color{
	background:#4396b7;
}
  
  .subDiv {
    margin: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    color: #fdf5f5;
    height: 400px;
    width: 400px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    background-color: #182a33;
}
 
  
  .course-heading-bottom-line {
	width: 40px;
	border: 1px solid   var(--hrBackground);
	background: var(--hrBackground);
	margin-bottom: 20px;
  }
  
  .sec1-image {
	width: 100%;
	border: none;
	object-fit: cover;
	border-radius: 10px;
  }

  .sec2-left {
	margin-left: 0;
    margin-right: 2%;
  }
  
  .childDiv {
	width: 100%;
	display: inline-flex;
  }
  
  .image-left {
	width: 48%;
    margin-right: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:  #4396b7;
    border: 11px solid var(--imageBackground);
    color: #2c3131;
    border-radius: 35px;
    height: 300px;
	font-size: 100px;
  }

  .webapps {
	font-size:90px;
	margin-right:10px;
  }
  
  
  .text-rightside {
	width: 48%;
	margin-left: 2%;
	display: flex;
  }
 
  .section-text{
	display: flex;
	align-items: center;
  }
  
 
  .under-line1 {
	margin-top: 0px;
	width: 40px;
	border: 1px solid var(--hrBackground);
    background: var(--hrBackground);
	margin-bottom: 10px;
  }
  .under-line2 {
	margin-top: 0px;
	width: 40px;
	border: 1px solid var(--hrBackground);
	background: var(--hrBackground);
	margin-bottom: 10px;
	margin-left: 228px;
  }
  
  .panelDivs {
	width: 100%;
	display: inline-flex;
	justify-content: space-evenly;
	margin-top: 30px;
  }
  
  .discovery {
    height: 250px;
    text-align: center;
    border: 1px solid #22404c;
    border-radius: 10px;
    background: #22404c;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .point-text {
	margin-bottom: 10px;
    padding: 3px;
    background: #4396b7;
    min-height: 40px;
    display: inline-flex;
    align-items: center;
    width: 100%;
    justify-content: center;
	border-radius: 10px;
  }
  .title-text {
	border-radius: 50%;
    background: #182a33;
    padding: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
	color: white;
  }
  
  .image {
	border-radius: 50%;
	width: 170px;
	height: 170px;
	margin-left: 10px;
	margin-right: 10px;
  }
  
  .discovery-paragraph {
	margin-left: 10px;
    margin-right: 10px;
    align-items: center;
    height: 100%;
	margin-top: 15px;
  }
  
  .direction {
	flex-direction: row-reverse;
  }
  
  .sec2-color {
	background-color: rgb(255, 240, 240);
  }
  
  .sec1-color {
	background-color: #CFD8DC;
  }
  
  .sec3-color {
	background-color: #ecdede;
	color: black
  }
  
  .innerSquare {
	background-color: white;
  height: 500px;
  width: 500px;
  border-radius: 50%;
}
  
.rainbowGradient {
 display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 18px;
  border-radius: 50%;
  background: linear-gradient(to right, #40c8ff , #ffffff, #e9e9e9, #40c8ff, #255a6b, white);
  width: 500px;
  height: 500px;
}


 @media (max-width: 995px) {
	
	.mainDiv {
		width: 90%;
		background: var(--mainBackground);
		padding-top: 40px;
		padding-left: 5%;
		padding-right: 5%;
		padding-bottom: 40px;
		padding-top: 50px;
		padding-bottom: 50px;
		text-align: center;
	}
	.rainbowGradient {
		display: inline-flex;
		 align-items: center;
		 justify-content: center;
		 padding: 18px;
		 border-radius: 50%;
		 background: linear-gradient(to right, #40c8ff , #ffffff, #e9e9e9, #40c8ff, #255a6b, white);
		 width: 300px;
		 height: 300px;
	   }
	   .innerSquare {
		background-color: white;
	  height: 300px;
	  width: 300px;
	  border-radius: 50%;
	}
	  
	  .subDiv {
		margin: auto;
		padding-top: 50px;
		padding-bottom: 50px;
		padding-left: 50px;
		padding-right: 50px;
		color: #fdf5f5;
		height: 200px;
		width: 200px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		background-color: #182a33;
	}
	.under-line1 {
		margin-top: 0px;
		width: 40px;
		background:  var(--hrBackground);
		margin-bottom: 10px;
	  }
	 
  
	.childDiv {
	  width: 100%;
	  display: inline-block;
	}
  
	.image-left {
	  width: 94%;
	  margin-left: 0px;
	  margin-bottom: 20px;
	  font-size: 75px;
	  margin-right: 0px;
	}
  
	
	.text-rightside {
	  width: 100%;
	}
  
	.panelDivs {
	  width: 100%;
	  display: inline-block;
	  justify-content: space-evenly;
	}
  
	.section-text {
		display: flex;
		align-items: center;
	}
  
	.discovery {
	  width: 90%;
	  margin-bottom: 30px;
	}
	.sec1-image {
	  width: 100%;
	  border: none;
	  object-fit: cover;
	  border-radius: 10px;
	}
	.text-header {
	  width: 100%;
	  padding-left: 0px;
	}
	
	.header {
	  width: 100%;
	}
	.image {
	  border-radius: 50%;
	  width: 170px;
	  height: 170px;
	  margin-left: 10px;
	  margin-right: 10px;
	}
	/*.text-heading {
	  width: 100%;
	  font-size: 40px;
	}*/
	/*.under-line2 {
	  margin-top: 0px;
	  width: 30px;
	  background:  var(--hrBackground);
	  margin-bottom: 10px;
	  margin-left: 173px;
	}*/
	.webapps {
		font-size:70px;
		margin-right:5px;
	  }
	  .mainHeading{
		font-family:Lokem-Heading;
	}
	.sec2-color {
		background-color: rgb(255, 240, 240);
	  }
	  
	  .sec1-color {
		background-color: #CFD8DC;
	  }
	  
	  .sec3-color {
		background-color: #ecdede;
		color: black
	  }
	  
  }
  