/* Base Styles */
.software-cycle-container {
  position: relative;
}

.circle-diagram {
  cursor: pointer;
}

.hover-popup {
  position: absolute;
  width: 250px; /* Fixed width for the popup */
  padding: 10px;
  border-radius: 8px;
  color: white;
  background-color: gray;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  z-index: 1000; /* Ensure the popup appears on top */
}

/* Mobile View Styles */
@media (max-width: 900px) {
 
    .hover-popup {
     position:relative;
      width: 90% !important; /* Full width for mobile */
      left: 50% !important;
      transform: translateX(-50%);
      bottom: 10px !important; /* Bottom of the screen */
      top: 20px !important; /* Ensure it's not tied to a top position */
      text-align: center !important;
      font-size: 12px !important;
      padding: 12px !important;
      z-index: 9999 !important;
      /*margin-left: 90px !important;*/
    }
  
    svg {
      width: 100%; /* SVG should be responsive */
      height: auto;
    }

  svg text {
    font-size: 8px; /* Smaller font size for text in the SVG */
  }

  .circle-diagram {
    cursor: pointer;
    padding-left: 5px;
  }

  .software-cycle-container {
    padding: 10px; /* Add some padding for mobile view */
  }
}
