

.main-heading{
	padding:14px;

}

.mainHeading{
    font-family: Lokem-Headings !important;
    font-size: 48px;
    padding-top: 25px;
}
.mainSubHeading{
	font-family:Lokem-medium !important;
    font-size: 30px;
	padding: 10px;
	color:#224f65 ;
   
    
}
.sub-heading{
	font-family:Lokem-medium !important;
    font-size: 30px;
	
}
.para-text{
		font-family: Lokem-lighter !important;
		letter-spacing: 0.2px;
		font-size: 20px;
		line-height: 25px;
	}


    
.text-para {
    font-family: "Lokem-lighter"!important;
  }
  .discovery-headertext {
	font-family: Lokem-Medium !important;
  }
  
  .discovery-paratext {
	font-family: "Lokem-Lighte" !important;
    font-size: 17px;
  }
 

@media (max-width: 995px) {
  .mainHeading{
    font-family: Lokem-Headings !important;
    font-size: 27px;
}
.mainSubHeading{
	font-family:Lokem-medium !important;
    font-size: 23px;
	padding: 10px;


}
.para-text{
  font-family: Lokem-lighter !important;
  letter-spacing: 0.2px;
  font-size: 20px;
  line-height: 22px;
}

}
 