



/* Full-width input fields */
#LoginForm input {
    width: 100%;
    padding: 15px;
    margin: 5px 0 0px 0;
    display: inline-block;
   
    background:white;
    border-radius: 0px;
    padding-bottom: 20px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    
  }
  
  /*#LoginForm  input[type=text]:focus, input[type=password]:focus {
    background-color: #ddd;
    outline: none;
  }*/
  h1{
      text-align: left;
      margin-bottom: 20px;
  }
  
  /*hr {
    border: 1px solid #f1f1f1;
    margin-bottom: 25px;
  }*/
  
  /* Set a style for all buttons */
  #LoginForm button {
    background-color: #cba53c;
    color: white;
    padding: 14px 20px;
    margin: 25px 0;
    border: none;
    cursor: pointer;
    width: 100%;
    border-radius: 0% !important;
  }
  
  button:hover {
    opacity:1;
  }
  
  /* Extra styles for the cancel button */
  .cancelbtn {
    padding: 14px 20px;
    background-color: #f44336;
  }
  
  /* Float cancel and signup buttons and add an equal width */
  /*.cancelbtn, .loginbtn {
    float: left;
    width: 50%;
  }*/
  
  /* Add padding to container elements */
   .admin-loginForm {
    padding: 16px;
    border:1px solid #ccc;
    width:420px;
    margin: auto;
    height: 450px;
    margin-bottom: 10px;
    margin-top: 10px;
    color: black !important;
    border: none;
    border-radius: 0px !important;
    background-color: #f3f3f3!important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
   
  }
  .lokem-button {
      border: none !important;
      border-radius: 5px !important;
     
  }
  .loginDiv{
      text-align: center;
  }
  
  /* Change styles for cancel button and signup button on extra small screens */
  @media screen and (max-width: 300px) {
    .cancelbtn, .loginbtn {
       width: 100%;
    }
  }
  