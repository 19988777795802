:root {
    --colorBackground:#224f65;
    }
    @font-face {
      font-family: "Lokem-Headings";
      src: url("../src/fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
      src: url("../src/fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
      src: url("../src/fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
      font-weight: 600;
    }
    
    @font-face {
      font-family: "Lokem-Lighter";
      src: url("../src/fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
      src: url("../src/fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
      src: url("../src/fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
      font-weight: 600;
    }
    
    @font-face {
      font-family: "Lokem-Medium";
      src: url("../src/fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
      src: url("../src/fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
      src: url("../src/fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
      font-weight: 600;
    }
  
  
  .main {
    background: var(--colorBackground) !important;
    
  }
  
  
  .section-content1 {
    width: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .mainHeading1 {
    width: 100%;
    text-align: center;
    color: #E0E0E0;
    text-align: center;
  }
  .main-lineup1  {
    width: 4%;
    border: 1.5px solid #4396b7;
    background: var(--hrBackground);
    margin-bottom: 15px;
   margin-left: 48%;
  }
  .main-lineup2{
    width: 4%;
    border: 1.5px solid #4396b7;
    background: #4396b7;
    margin-bottom: 15px;
    margin-left: 48%;
  }
  

  .main-para {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    color:#CFD8DC;
    padding-bottom: 5%;
  }
  .mainHeading2 {
    text-align: center;
    color:#224f65;
    padding-left: 0px;
    padding-right: 0px;
  }
  .back-color{
    background-color: #CFD8DC;
  }
  .section-content2 {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 20px;
  }
  .main-para1 {
    width: 39%;
    margin-left: 32%;
    color: black;
    padding-bottom: 20px;
  }
  
  .section-content3-row1 {
    width: 100%;
    padding-top: 25px;
    padding-bottom: 20px;
    display: inline-flex;
  }
  .frontend-col1 {
    width: 49%;
    margin-left: 1%;
  }
  .backend-col2 {
    width: 49%;
    margin-left: 1%;
  }
  .frontend-heading {
    padding-bottom: 20px;
    padding-left: 10px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  
  .frontent-para {
    padding-bottom: 20px;
    padding-left: 38px;
  }
  .text {
    color: #4396b7;
    padding-bottom: 20px;
    padding-left: 10px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .backend-para {
    padding-bottom: 20px;
    padding-left: 38px;
  }
  .text-one {
    color: #4396b7;
    padding-bottom: 20px;
    padding-left: 10px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  
  .backend-heading {
    padding-bottom: 20px;
    padding-left: 10px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .col1-total {
    width: 80%;
    margin-left: 18%;
    margin-right: 2%;
  }
  .col2-total {
    width: 80%;
    margin-left: 2%;
    margin-right: 18%;
  }
  @media (max-width: 995px) {
    
    .back-color{
      background-color: #EEEEEE;
    }
    .section-content1 {
      width: 100%;
      background-color: hsl(200, 50%, 26%);
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .mainHeading1 {
      width: 99%;
      text-align: center;
      color: #ffff;
      
    }
    .heading-line{
      text-align: center;
    }
  
    .main-lineup1  {
      width: 10%;
    border: 1.5px solid #4396b7;
    background: #4396b7;
    margin-bottom: 15px;
    margin-left: 44%;
  }
    .main-lineup2{
      width: 10%;
      border: 1.5px solid #4396b7;
      background: #4396b7;
      margin-bottom: 15px;
      margin-left: 44%;
    }
    .main-para {
      width: 90%;
      margin-left: 0%;
      color: #ffff;
      padding-top: 10px;
      margin-left: 5%;
      margin-right: 5%;
      text-align: center;
    }
    .heading-line1{
      text-align: center;
    }
    .mainHeading2 {
      text-align: center;
      color: black;
      width:85%;
    }
  
    .section-content2 {
      width: 100%;
      padding-top: 0px;
      padding-bottom: 5px;
      padding-left: 0%;
      padding-right: 0%;
    }
    .main-para1 {
      width: 90%;
      color: black;
      margin-left: 5%;
    margin-right: 5%;
    text-align: center;
    }
    .section-content3-row1 {
      width: 100%;
      padding-top: 0px;
      padding-bottom: 0px;
      display: inline-block;
    }
    .frontend-col1 {
      width: 100%;
      margin-left: 0%;
    }
    .backend-col2 {
      width: 100%;
      margin-left: 0%;
    }
    .frontend-heading {
      padding-bottom: 20px;
      padding-left: 0;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
    }
  
    .frontent-para {
      width:90%;
      padding-bottom: 20px;
      padding-left: 0;
      margin-left: 5%;
      margin-right: 5%;
      
    }
    .text {
      color: #4396b7;
      padding-bottom: 20px;
      padding-left: 0px;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
    }
    .backend-para {
      width:90%;
      padding-bottom: 20px;
      padding-left: 0;
      margin-left: 5%;
      margin-right: 5%;
    }
    .text-one {
      
      color: #4396b7;
      padding-bottom: 20px;
      padding-left: 0px;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
    }
  
    .backend-heading {
      padding-bottom: 20px;
      padding-left: 0px;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
    }
    .col1-total {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
    .col2-total {
      width: 100%;
      margin-left: 0%;
      margin-right: 0%;
    }
  }
  