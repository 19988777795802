@font-face {
	font-family: "Lokem-Headings";
	src: url("../src/fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
	src: url("../src/fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
	src: url("../src/fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
	font-weight: 600;
}

@font-face {
	font-family: "Lokem-Lighter";
	src: url("../src/fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
	src: url("../src/fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
	src: url("../src/fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
	font-weight: 600;
}

@font-face {
	font-family: "Lokem-Medium";
	src: url("../src/fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
	src: url("../src/fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
	src: url("../src/fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
	font-weight: 600;
}
:root {
	--mainBackground:#224f65;
	--imageBackground: #4396b7;
	--hrBackground: #4396b7;
}
.footer {
	background: #182a33 !important;	
	color: #fff !important;
	padding-top:10px;
	padding-left: 20px;
	padding-right: 20px;
}
.menu-items-footer-container {
    text-align: center;
	color: #fff !important;
    width: 100%;
    display: inline-flex;
    padding-top: 20px;
    border-bottom: 1px solid var(--hrBackground);
    padding-bottom: 8px;
	font-family: Lokem-Lighter;
}
.menu-items-footer {
	list-style-type: none;
	margin: 0;
	padding: 0;
	width: 70%;
	float: left;
	text-align: left;
}

.menu-items-footer li {
	display: inline;
	padding-right: 30px;
	font-weight: normal;
	font-family: Lokem-Lighter;
	color: #fff;
	text-decoration: none;
}
a{
	text-decoration: none;
}
.menu-items-footer li:hover {
	cursor: pointer;
}

.copyRightText {
    width: 100%;
    text-align: center;
    font-family: Lokem-Lighter;
    padding-bottom: 25px;
    padding-top: 10px;
}
.CookiePolicyText{
	width: 100%;
    text-align: center;
    font-family: Lokem-Lighter;
    padding-bottom: 0px;
    padding-top: 10px;
	color: #fff !important;
}
#Footer a{
	color: #fff;	
}
li{
	color: #fff;
	border-bottom: none;
}
@media (max-width: 995px) { 
	.footer {
		background: #78909c;
		color: #fff;
		padding-top: 25px;
		padding-bottom: 20px;
	}
	.menu-items-footer-container {
		text-align: center;
		width: 100%;
		display: inline-flex;
		padding-top: 0;
		border-bottom: none;
		padding-bottom: 8px;
	}
	.menu-items-footer {
		list-style-type: none;
		margin: 0;
		padding: 0;
		padding-bottom: 0;
		width: 100%;
		float: initial;
		text-align: center;
	}
	.menu-items-footer li {
		display: unset;
		padding-left: 5px;
		padding-right: 5px;
		font-weight: normal;
		font-family: Lokem-Lighter;
		border-right: none;
		text-decoration:underline ;
	}
	.menu-items-footer li:first-child {
		padding-left: 0;
	}
	
	}