:root {
	--hrBackground: #4396b7;
}

@font-face {
	font-family: "Lokem-Headings";
	src: url("../src/fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
	src: url("../src/fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
	src: url("../src/fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
	font-weight: 600;
}

@font-face {
	font-family: "Lokem-Lighter";
	src: url("../src/fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
	src: url("../src/fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
	src: url("../src/fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
	font-weight: 600;
}

@font-face {
	font-family: "Lokem-Medium";
	src: url("../src/fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
	src: url("../src/fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
	src: url("../src/fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
	font-weight: 600;
}
.mainBox {
    width: 90%;
    text-align: center;
    margin-left: 5%;
    margin-right: 5%;
    padding-bottom: 10px;
}

.main-heading {

	text-align: center !important;
	font-size: 40px;
	margin-bottom: 25px;
	margin-left: 25px;
	margin-right: 25px;
	color:green;
}

.inputField {
	width: 80% !important;
	font-size: 22px;
	margin-top: 5px;
	margin-bottom: 5px;
	height: 45px;
	text-align: left;
	margin-left: 10%;
	margin-right: 10%;
	padding-left: 5px;
}
.sub-btn{
    text-align: left;}
.attendance-btn-main {
		color: #d5a215;
		border-radius: 5px;
		margin-top: 5px;
		margin-bottom: 5px;
		background: #66bb6a;
		width: 130px;
		border: none;
		cursor: pointer;
		padding-bottom: 10px;
		border-radius: 10px;
		font-size: 18px;
		color: white;
		padding-top: 10px;
		margin-top: 30px;
		text-align: center;
		margin-right: 30px;
	}	
	.cancle-btn{
		text-align: right;}
	.cancle-main {
		color: #d5a215;
		border-radius: 5px;
		margin-top: 5px;
		margin-bottom: 5px;
		background: #e86c6c;
		width: 130px;
		border: none;
		cursor: pointer;
		padding-bottom: 10px;
		border-radius: 10px;
		font-size: 18px;
		color: white;
		padding-top: 10px;
		margin-top: 30px;
		text-align: center;
		margin-left: 30px;
		}	
	.sub-cancle-btn{
		display :inline-flex;
		margin-top: 20px;
		margin-bottom: 20px;
		}

		@media (max-width: 995px) {
			.sub-cancle-btn{
				display :inline-flex;
				margin-top: 20px;
				}
			.attendance-btn-main {
				color: #d5a215;
				border-radius: 5px;
				margin-top: 5px;
				margin-bottom: 5px;
				background: #66bb6a;
				width: 130px;
				border: none;
				cursor: pointer;
				padding-bottom: 10px;
				border-radius: 10px;
				font-size: 18px;
				color: white;
				padding-top: 10px;
				margin-top: 30px;
				text-align: center;
				margin-right: 16px;
			}
			.cancle-main {
				color: #d5a215;
				border-radius: 5px;
				margin-top: 5px;
				margin-bottom: 5px;
				background: #e86c6c;
				width: 130px;
				border: none;
				cursor: pointer;
				padding-bottom: 10px;
				border-radius: 10px;
				font-size: 18px;
				color: white;
				padding-top: 10px;
				margin-top: 30px;
				text-align: center;
				margin-left: 16px;
				}	
				.mainBox {
					width: 100%;
					text-align: center;
					margin-left: 0%;
					margin-right: 0%;
					padding-bottom: 10px;
				}
				.main-heading {

					text-align: center;
					font-size: 35px;
					margin-top: 10px;
					margin-bottom: 20px;
					margin-left: 25px;
					margin-right: 25px;
					color:green;
				}
					
	
		}
		 