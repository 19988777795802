@font-face {
    font-family: "Lokem-Headings";
    src: url("./fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
    src: url("./fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
    src: url("./fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
    font-weight: 600;
  }
  
  @font-face {
    font-family: "Lokem-Lighter";
    src: url("./fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
    src: url("./fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
    src: url("./fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
    font-weight: 600;
  }
  
  @font-face {
    font-family: "Lokem-Medium";
    src: url("./fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
    src: url("./fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
    src: url("./fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
    font-weight: 600;
  }
 

  
  #login {
    max-width: 400px;
    width: 60%;
    margin: auto;
  }
  
  
  input[type="text"],
  input[type="password"] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
  
  button {
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
  }
  
  button:hover {
    opacity: 0.8;
  }
  
  .cancelbtn {
    width: auto;
    padding: 10px 18px;
    background-color: #f44336;
  }
  
  .imgcontainer {
    text-align: center;
    margin: 24px 0 12px 0;
  }
  
  img.avatar {
    width: 40%;
    border-radius: 50%;
  }
  
  .container {
    padding: 16px;
    padding-top: 30px;
  }
  
  span.psw {
    float: right;
    padding-top: 16px;
  }
  .form-error {
    display: none;
    color: red;
  }
  
  /* Change styles for span and cancel button on extra small screens */
  @media screen and (max-width: 300px) {
    span.psw {
      display: block;
      float: none;
    }
    .cancelbtn {
      width: 100%;
    }
  }
  @media (max-width: 767px) {
    #login {
      max-width: initial;
      width: 100%;
      margin: auto;
    }
  }
  