/*:root {
    --colorPrimaryText: #fff;
    --p-colorPrimaryHover: #0b81fa;
    --p-colorPrimaryActive: #228cfa;
    --p-colorPrimaryAlpha20: hsla(210, 96%, 45%, 25%);
    --p-colorPrimaryAlpha40: hsla(210, 96%, 45%, 40%);
    --p-colorPrimaryAlpha50: hsla(210, 96%, 45%, 50%);
    --p-colorPrimaryDisabled: #d9d9d9;
    --p-colorPrimaryDisabledText: #8d8d8d;
    --colorBackgroundText: #30313d;
    --p-colorBackgroundDivider: #f2f2f2;
    --p-colorBackgroundDisabled: #f2f2f2;
    --p-colorBackgroundDisabledDeemphasize05: #e6e6e6;
    --p-colorBackgroundDeemphasize03: #f7f7f7;
    --p-colorBackgroundDeemphasize05: #f2f2f2;
    --p-colorBackgroundDeemphasize10: #e6e6e6;
    --p-colorBackgroundDeemphasize15: #d9d9d9;
    --p-colorBackgroundDeemphasize20: #cccccc;
    --p-colorBackgroundLightenAbsolute05: #ffffff;
    --p-colorBackgroundContrastAlpha05: rgba(0, 0, 0, 0.05);
    --p-colorBackgroundContrastAlpha08: rgba(0, 0, 0, 0.08);
    --p-colorBackgroundContrastAlpha30: rgba(0, 0, 0, 0.3);
    --colorTextSecondary: #6d6e78;
    --colorTextPlaceholder: #77787e;
    --colorSuccessText: #fff;
    --colorDangerText: #fff;
    --colorWarningText: #30313d;
    --fontFamily: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    --fontSmooth: always;
    --fontVariantLigatures: normal;
    --fontVariationSettings: normal;
    --fontLineHeight: 1.45;
    --fontSizeBase: 1rem;
    --fontSizeSm: 0.93rem;
    --fontSizeXs: 0.875rem;
    --fontSize2Xs: 0.8125rem;
    --fontSize3Xs: 0.75rem;
    --fontSizeLg: 1.0625rem;
    --fontSizeXl: 1.125rem;
    --fontWeightLight: 300;
    --fontWeightNormal: 400;
    --fontWeightMedium: 600;
    --fontWeightBold: bold;
    --colorPrimary: #0570de;
    --colorBackground: #fff;
    --colorText: #30313d;
    --colorSuccess: #30b130;
    --colorDanger: #df1b41;
    --colorWarning: #f6e6b9;
    --colorIcon: var(--colorTextSecondary);
    --colorIconHover: var(--colorText);
    --colorIconCheckmark: var(--colorPrimaryText);
    --colorIconCardError: var(--colorDanger);
    --colorIconCardCvc: var(--colorIcon);
    --colorIconCardCvcError: var(--colorDanger);
    --colorIconChevronDown: var(--colorIcon);
    --colorIconChevronDownHover: var(--colorIconHover);
    --colorIconClose: var(--colorIcon);
    --colorIconCloseHover: var(--colorIconHover);
    --colorIconLoadingIndicator: var(--p-colorBackgroundContrastAlpha30);
    --colorIconMenu: var(--colorPrimary);
    --colorIconMenuHover: var(--colorIconMenu);
    --colorIconMenuOpen: var(--colorIconMenu);
    --colorIconPasscodeDevice: var(--colorIcon);
    --colorIconPasscodeDeviceHover: var(--colorIconHover);
    --colorIconPasscodeDeviceNotification: var(--colorPrimary);
    --colorIconRedirect: currentColor;
    --colorIconTab: var(--colorIcon);
    --colorIconTabHover: var(--colorText);
    --colorIconTabSelected: var(--colorPrimary);
    --colorIconTabMore: var(--colorIcon);
    --colorIconTabMoreHover: var(--colorText);
    --spacingUnit: 0.25rem;
    --spacingGridRow: var(--p-spacing3);
    --spacingGridColumn: var(--p-spacing3);
    --spacingTab: var(--p-spacing1);
    --spacingPickerItem: var(--p-spacing2);
    --borderRadius: 5px;
    --focusBoxShadow: 0 0 0 3px var(--p-colorPrimaryAlpha20),
      0 1px 1px 0 var(--p-colorBackgroundContrastAlpha08);
    --focusOutline: 0;
    --p-spacingXs: 1px;
    --p-spacingSm: 2px;
    --p-spacing1: 0.25rem;
    --p-spacing2: 0.5rem;
    --p-spacing3: 0.75rem;
    --p-spacing4: 1rem;
    --p-spacing5: 1.25rem;
    --p-spacing6: 1.5rem;
    --p-spacing7: 1.75rem;
    --p-spacing8: 2rem;
    --p-spacing9: 2.25rem;
    --p-spacing10: 2.5rem;
    --p-logoLightDisplay: block;
    --p-logoDarkDisplay: none;
    --p-logoTabLightDisplay: block;
    --p-logoTabDarkDisplay: none;
    --p-logoTabSelectedLightDisplay: block;
    --p-logoTabSelectedDarkDisplay: none;
    --p-logoBlockLightDisplay: block;
    --p-logoBlockDarkDisplay: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }*/
  
  @font-face {
    font-family: "Lokem-Headings";
    src: url("../src/fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
    src: url("../src/fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
    src: url("../src/fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
    font-weight: 600;
  }
  
  @font-face {
    font-family: "Lokem-Lighter";
    src: url("../src/fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
    src: url("../src/fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
    src: url("../src/fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
    font-weight: 600;
  }
  
  @font-face {
    font-family: "Lokem-Medium";
    src: url("../src/fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
    src: url("../src/fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
    src: url("../src/fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
    font-weight: 600;
  }
  body {
    margin: 0;
    font-family: Lokem-Headings;
    overflow-x: hidden;
  }
  #TermsAndConditions ul,a,li,ol{
    text-decoration: none;
    color:#fff;
  }
  
  /*Header*/
  .terms-header-top-page {
    background-image: url(./termsImage.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .header-top-page {
    /*background: url(../media/images/first-page.png);*/
    min-height: 890px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .terms-first-container {
    text-align: center;
    margin-left: 23%;
    margin-right: 23%;
    color: #fff;
    padding-bottom: 150px;
    padding-top: 100px;
  }
  .terms-page-heading {
    font-family: "Lokem-Lighter";
    font-size: 60px;
    color: #fff;
  }
  .terms-page-subHeading {
    font-family: "Lokem-Lighter";
    font-size: 30px;
  }
  .tc-body-container {
    font-family: Lokem-Lighter;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .introductionText {
    font-family: Lokem-Headings;
    font-size: 27px;
  }
  
  
  @media (max-width: 995px) {
    .tc-body-container {
        font-family: Lokem-Lighter;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        font-size: 18px;
        letter-spacing: 0.5px;
      }
      
    .terms-first-container {
        text-align: center;
        font-size: 20px;
        margin-left: 3%;
        margin-right: 3%;
        color: #fff;
        padding-bottom: 100px;
        padding-top: 50px;
     
     }
     .terms-page-heading {
        font-size: 45px;
      }
    
    }