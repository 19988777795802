
@font-face {
	font-family: "Lokem-Headings";
	src: url("../src/fonts/ReadexPro/ReadexPro-bold.eot") format("eot");
	src: url("../src/fonts/ReadexPro/ReadexPro-bold.woff") format("woff");
	src: url("../src/fonts/ReadexPro/ReadexPro-bold.ttf") format("truetype");
	font-weight: 600;
}

@font-face {
	font-family: "Lokem-Lighter";
	src: url("../src/fonts/ReadexPro/ReadexPro-Light.eot") format("eot");
	src: url("../src/fonts/ReadexPro/ReadexPro-Light.woff") format("woff");
	src: url("../src/fonts/ReadexPro/ReadexPro-Light.ttf") format("truetype");
	font-weight: 600;
}

@font-face {
	font-family: "Lokem-Medium";
	src: url("../src/fonts/ReadexPro/ReadexPro-Medium.eot") format("eot");
	src: url("../src/fonts/ReadexPro/ReadexPro-Medium.woff") format("woff");
	src: url("../src/fonts/ReadexPro/ReadexPro-Medium.ttf") format("truetype");
	font-weight: 600;
}
.header-container {
	display: block;
	width: 100%;
	background-color:#182a33;
	padding-bottom: 10px;
}
.logo-container {
	width: 100%;
    height: auto;
    padding-top: 20px;
    text-align: left;
    display: inline-block;
    padding-bottom: 25px;
    padding-left: 10px;
}

.logo-img {
	width: 300px;
	cursor: pointer;
}
.menu-container {
    position: absolute;
    display: flex;
    right: 40px;
    top: 15px;
}
.bars-icon {
	color: #c9a328;
	padding: 15px;
	display: block;
	font-size: 40px;
	float: right;
	padding-right: 10%;
	margin-top: 20px;

}
.fa-bars {
	font-size: 50px;
	color: #FAFAFA !important;
	cursor: pointer;
}
.menu-items-container {
	padding-right: 0px;
    color: #fff;
    position: absolute;
    top: 45px;
	font-family: Lokem-Lighter;
    width: 100%;
    right: 0px;
    text-align: right;
	margin-right: 80px;
	
}
.menu-items {
	display: inline-flex;
}

.menu-items li a {
    text-decoration: none !important; /* Remove underline from links */
    color: #fff !important; /* Set link color to white */
}

.menu-items ul li a, /* Target links in unordered lists */
.menu-items ol li a { /* Target links in ordered lists */
    text-decoration: none !important; /* Ensure no underline */
    color: #fff !important; /* Ensure white color */
}
.menu-items li {
	display: block;
    text-align: right;
    padding-bottom: 20px;
    font-size: 22px;
    font-family: Lokem-Lighter;
    padding-right: 20px;
	color: #FAFAFA;
	text-decoration: none !important;
	border-bottom: none;
	letter-spacing: 1px;
}
.menu-items li:hover {
	cursor: pointer;
	color:#4396b7;
}
.login-button-slake{
	height: 32px;
	width: 100px;
	color:#fff;
	font-size: 16px;
	cursor: pointer;
	font-family: lokem-lighter;
	background: #cba53c;
}
.lokem-button {
    border: none !important;
    border-radius: 5px !important;
}
@media (max-width: 995px) { 
	.logo-container {
		width: auto;
		height: auto;
		padding-top: 20px;
		text-align: center;
		display: inline-block;
	}
	.logo-img {
		width: 150px;
		cursor: pointer;
		margin-left: 0px;
		margin-top: 0px;
	}
	.menu-container {
		position: absolute;
		display: flex;
		right: 20px;
		top: 20px;
	}
	
	.bars-icon {
		color: white;
		padding: 0px;
		padding-left: 15px;
		display: block;
		font-size: 30px;
		float: right;
		padding-right: 10%;
		margin-top: 0px;
	}
	.menu-items-container {
		padding-right: 0px;
		color: #fff;
		position: absolute;
	    margin-right: 0px;
		top:76px;
		font-family: Lokem-Headings;
		z-index: 1000;
		width: 100%;
		background: #4396b7;
	}
	.menu-items {
		display: block;
	}
	.menu-items li {
		text-align: right;
		padding-bottom: 10px;
		font-size: 21px;
		font-family: Lokem-Lighter;
		padding-right: 28px;
	}
	
	.menu-items li:hover {
		cursor: pointer;
		color:#182a33;
	}
	
	

}